import React from 'react';
import { graphql } from 'gatsby';
import loadable from '@loadable/component';

import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Hero } from '../components/General/Hero';
import { WaveUpSVG } from '../components/WaveSVGs/WaveUpSVG';
import { WaveDownSVG } from '../components/WaveSVGs/WaveDownSVG';
import { SEO } from '../components/SEO';
import { Resources } from '../components/General/Resources';
import { ProductFeatureBody } from '../components/ProductFeature/ProductFeatureBody';
import { HeroVideo } from '../components/FinancialServices/Product/Hero/HeroVideo';

const Form = loadable(() => import('../components/ContactForm/Form'));

const useStyles = makeStyles((theme) => ({
  formCont: {
    padding: '24rem 0',
    [theme.breakpoints.down('md')]: {
      padding: '16rem 0',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '8rem 0',
    },
  },
  faqTitle: {
    color: theme.workwaveBlue,
  },
}));

const Wavelytics = ({ data, location }) => {
  const wavelytics = data.wavelytics.edges[0].node;
  const classes = useStyles();

  const {
    hero,
    metaDescription,
    metaTitle,
    contactForm,
    formBgImage,
    pardotUrl,
    resourceTitle,
    resources,
    _rawResourceBody,
    thumbnailImage,
    wistiaLink,
  } = wavelytics;
  return (
    <>
      <SEO title={metaTitle} description={metaDescription} />
      <Hero hero={hero} finServ />
      <Container>
        <HeroVideo
          thumbnailImage={thumbnailImage}
          wistiaLink={wistiaLink}
          noFormBar
        />
      </Container>
      <ProductFeatureBody productFeature={wavelytics} wavelytics />
      <div>
        <WaveDownSVG height='213' width='100%' fill='white' />
      </div>

      <Form
        pardotUrl={pardotUrl}
        contactForm={contactForm}
        privacy
        modal={false}
        location={location}
        noForm
      />

      <Container>
        <Resources
          header={resourceTitle}
          resources={resources}
          subheader={_rawResourceBody}
        />
      </Container>
      <div>
        <WaveUpSVG height='213' width='100%' fill='#f4f8ff' />
      </div>
    </>
  );
};

export const query = graphql`
  query WavelyticsPageQuery {
    wavelytics: allSanityWavelytics {
      edges {
        node {
          metaTitle
          metaDescription
          hero {
            ctaText
            backgroundImage {
              asset {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            statsImage {
              asset {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            _rawContent
          }
          thumbnailImage {
            asset {
              gatsbyImageData
            }
          }
          wistiaLink
          featureHeader
          featureSection: productFeatureSection {
            _key
            title
            cardTitle
            cardFaIcon
            cardIconColor {
              hexValue
            }
            altSoftwareLogo {
              asset {
                gatsbyImageData
              }
            }
            bodySubHeader
            bodyHeader
            image {
              featureImage {
                asset {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
            }
            videoVariant
            androidLink
            iosLink
            qbLink
            # svgVariant
            thumbnailVideoUrl
            ctaText
            ctaLink
            internalLink
            _rawBodyCopy
          }
          testimonialTitle
          testimonial {
            title
            header
            testimonialLogo {
              asset {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            company
            testimonialText
            nameAndTitle
            videoVariant
            image {
              asset {
                gatsbyImageData(
                  fit: FILLMAX
                  height: 450
                  width: 775
                  placeholder: BLURRED
                )
              }
            }
          }
          testimonialBackground {
            asset {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
          customerLogosHeader
          customerLogos {
            logo {
              asset {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          contactForm {
            header
            privacyPolicy
          }
          formBgImage {
            asset {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
          pardotUrl
          resourceTitle
          _rawResourceBody
          resources {
            title
            image {
              asset {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            blurb
            ctaText
            ctaLink
          }
        }
      }
    }
  }
`;

export default Wavelytics;
